import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';

import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';

import { styles } from '../styles/shared-styles';

interface IReceipt {
  id: number,
  created_at: string,
  customer_phone: number,
  services_made: string,
}

@customElement('app-home')
export class AppHome extends LitElement {

  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  @property() title = 'asd'; //new Date().toString();
  @property() receipts:Array<IReceipt> = [];

  static styles = [
    styles,
    css`
      sl-card { width: 100%; margin-bottom: 15px }
    `
  ];

  async firstUpdated() {
    // lit lifecycle https://lit.dev/docs/components/lifecycle/
    console.log('This is your home page');
    this.getReceipts();
  }

  makeMessage(items: Array<string>) {
    const bold = (txt: string) => `*${txt}*`;
    const italic = (txt: string) => `_${txt}_`;
    const list = (txt: string) => `- ${txt}`;
    const breakLine = (repeat = 0) => {
      let txt = '%0A';
      for (let i = 0; i < repeat - 1; i++) {
        txt += txt;
      }
      return txt;
    };
    const msg = [
      bold('Resumo do serviço prestado:'),
      items.map(e => list(e)).join(breakLine()).concat(breakLine()),
      italic('Em caso de problemas, estamos aqui para ajudar. Entre em contato conosco.')
    ];
    return msg.join(breakLine());
  }

  sendWhatsappMessage() {
    
  }

  getReceipts() {
    const api = 'https://oil-poc-server.felipemartins81.workers.dev/api';
    fetch(api + '/receipts').then(async (res) => {
      const response = await res.json();
      this.receipts = response;
    })
  }

  addTestMsg() {
    this.receipts.push({
        "id": 798,
        "created_at": "2024-10-14T19:11:14.000Z",
        "customer_phone": 11982957521,
        "services_made": "test 1, test 2"
    })
    console.log('👀 ~ addTestMsg:', this.receipts);
    this.requestUpdate();
    // this.title = new Date().toString();
  }

  render() {
    return html`
      <main>

        ${this.receipts.map((receipt) => html`
          <sl-card>
            ${receipt.id} <br>
            ${new Date(receipt.created_at).toLocaleString()}<br>
            ${receipt.customer_phone} <br>
            ${receipt.services_made} <br>
          </sl-card>
        `)}

        <div id="welcomeBar">
          <sl-card id="welcomeCard">
            <div slot="header">
              <h2>${this.title}</h2>
            </div>

            <sl-button @click=${this.addTestMsg}>Add</sl-button>
            <br><br>

            <!-- mobile link // href="whatsapp://send?phone=5511982957521&text=Teste" -->
            <sl-button href="https://api.whatsapp.com/send?phone=5511982957521&text=${this.makeMessage(['item 1', 'item 2'])}" target="_blank">
              Desktop link</sl-button>

            <p>
              For more information on the PWABuilder pwa-starter, check out the
              <a href="https://docs.pwabuilder.com/#/starter/quick-start">
                documentation</a>.
            </p>

            <p id="mainInfo">
              Welcome to the
              <a href="https://pwabuilder.com">PWABuilder</a>
              pwa-starter! Be sure to head back to
              <a href="https://pwabuilder.com">PWABuilder</a>
              when you are ready to ship this PWA to the Microsoft Store, Google Play
              and the Apple App Store!
            </p>

            ${'share' in navigator
              ? html`<sl-button slot="footer" variant="default" @click="${this.sendWhatsappMessage}">
                        <sl-icon slot="prefix" name="share"></sl-icon>
                        Share this Starter!
                      </sl-button>`
              : null}
          </sl-card>

          <sl-card id="infoCard">
            <h2>Technology Used</h2>

            <ul>
              <li>
                <a href="https://www.typescriptlang.org/">TypeScript</a>
              </li>

              <li>
                <a href="https://lit.dev">lit</a>
              </li>

              <li>
                <a href="https://shoelace.style/">Shoelace</a>
              </li>

              <li>
                <a href="https://github.com/thepassle/app-tools/blob/master/router/README.md"
                  >App Tools Router</a>
              </li>
            </ul>
          </sl-card>

          <sl-button href="${resolveRouterPath('about')}" variant="primary">Navigate to About</sl-button>
        </div>
      </main>
    `;
  }
}
